<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>

   
    <ThirdPartyForm
        v-if="service"
        :loading="loading"
        :selectedService="service"
        :formErrors="formErrors"
        :action="QUERY_ACTIONS_EDIT"
        @onSubmitSuccess="handleUpdateSuccess"
        @onFormUpdated="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import ThirdPartyForm from "@/views/Pages/AdminModule/ThirdPartyServiceModule/partials/ThirdPartyForm.vue";
import alertLeave from "@/mixins/alert-leave-mixin";
import {
  QUERY_ACTIONS_EDIT,
} from "@/constants/common";


export default {

  name : "EditThirdPartyComponent",

  layout: "DashboardLayout",

  components: { 
    ThirdPartyForm 
  },

  mixins: [alertLeave],

  props: {
    serviceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      service : null,
      formErrors: null,
      loading: false,
      QUERY_ACTIONS_EDIT,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.serviceId || this.service?.id || this.$route.query.id;
        await this.$store.dispatch("thirdPartyServices/get", id);
        this.service = this.$store.getters["thirdPartyServices/item"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

   
    handleUpdateSuccess(service){
      this.$emit("onUpdateSucces", service);
    }
  },
};
</script>
