<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <reseller-selector
        v-if="
          !filterReseller &&
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
        "
        @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
      />
      <organization-selector
        v-if="
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
        @organizationChanged="
          (organizationId) => (selectedOrganization = organizationId)
        "
      />
      
      <base-input
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="rows"
          :loading="loading"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <!-- <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            sortable="custom"
            min-width="220"
          /> -->

      
          <el-table-column
            :label="$t('THIRD_PARTY_SERVICE.SERVICE_TYPE')"
            prop="slug"
            sortable="custom"
        
          />

          <el-table-column
            :label="$t('THIRD_PARTY_SERVICE.SERVICE_MODE')"
            prop="mode"
            sortable="custom"
      
          />
          
          <el-table-column
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            :label="$t('COMMON.ORGANIZATION')"
            prop="organization"
            min-width="220"
          >
            <template v-slot="{ row }">
              <object-link :object="row.organization" />
            </template>
          </el-table-column>
          <el-table-column
            v-if="
              !filterReseller &&
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
            "
            :label="$t('COMMON.RESELLER')"
            prop="reseller"
            min-width="220"
          >
            <template v-slot="{ row }">
              <object-link :object="row.reseller" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
          <template v-slot="{ row }">
              {{ $formatDate(row.created_at) }}
            </template>
        </el-table-column>
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_THIRD_PARTY_SERVICES)"
              >
                <a
                  type="text"
                  @click="viewRow(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt" />
                </a>
              </el-tooltip>
              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_THIRD_PARTY_SERVICES)"
              >
                <a
                  type="text"
                  @click="editRow(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>
              <el-tooltip
                :content="$t('COMMON.DELETE')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_THIRD_PARTY_SERVICES)"
              >
                <a
                  type="text"
                  @click="deleteService(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt" />
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";

import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";

export default {

  name: "ThirdPartyListTable",

  components: {
    BasePagination,
    OrganizationSelector,
    ResellerSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      rows: [],
      loading: true,
      selectedOrganization: null,
      selectedReseller: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        this.rows = [];
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,reseller",
        };


        if (this.selectedOrganization) {
          params.filter.organization = this.selectedOrganization;
        }
        if (this.filterReseller && !this.filterOrganization) {
          params.filter.reseller = this.selectedReseller;
        }

        
        await this.$store.dispatch("thirdPartyServices/list", params);

        this.rows = this.$store.getters["thirdPartyServices/list"];

        this.total = this.$store.getters["thirdPartyServices/listTotal"];
        this.loading = false;

      } catch (error) {
        console.error("TPS[gestList] => ",error);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteRow(row) {
      this.$emit("onDeleteRow", row);
    },
    

    viewRow(row) {
      console.table(row)
      this.$emit("onViewRow", row);
    },

    editRow(row) {
      this.$emit("onEditRow", row);
    },


    async deleteService(service) {
      const that = this;
       await swal.fire({
     
             title: this.$t("THIRD_PARTY_SERVICE.DELETE_THIS_SERVICE"),
             type: "question",
             buttonsStyling: false,
             showCancelButton: true,
             confirmButtonText: this.$t("COMMON.YES"),
             cancelButtonText: this.$t("COMMON.NO"),
             confirmButtonClass: "btn btn-primary",
             cancelButtonClass: "btn btn-warning",
             showLoaderOnConfirm : that.loading,
             allowOutsideClick: () => !swal.isLoading(),
             
             preConfirm : async function(){
               try {
                   that.loading = true;
     
                   const {status} = await that.$store.dispatch("thirdPartyServices/destroy", service.id);
                  
                   that.$emit("onDeleteSuccess", service);

                   that.$notify({
                     type: "success",
                     message: that.$t("THIRD_PARTY_SERVICE.SERVICE_DELETED"),
                   });

                  await that.getList();
                  
                  
             
               } catch (error) {
                 that.loading = false;
                 that.$notify({
                   type: "danger",
                   message: that.$t("ERRORS.SOMETHING_WENT_WRONG"),
                 });
               }
             }
           });
      
     
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
