<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("THIRD_PARTY_SERVICE.SERVICES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_THIRD_PARTY_SERVICES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{$t("THIRD_PARTY_SERVICE.ADD_SERVICE") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_THIRD_PARTY_SERVICES)"
              :objectType="'third-party-services'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

     <ThirdPartyListTable
        @onViewRow="onViewService"
        @onEditRow="onEditService"
        :key="renderKey * 100"
        ref="servicesListTable"
      /> 

      <div v-if="isViewModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" 
        v-resizable-modal="'SERVIVE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{$t("THIRD_PARTY_SERVICE.SERVICE_PREVIEW") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="selectedService"
                :objectType="'third-party-services'"
                :objectId="selectedService.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v" />
                </template>
                <button 
                  v-if="$currentUserCan($permissions.PERM_EDIT_THIRD_PARTY_SERVICES)"
                  class="edit"
                  @click="openEditModal"
                >
                  <i class="fal fa-edit" />
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button 
                  v-if="$currentUserCan($permissions.PERM_DELETE_THIRD_PARTY_SERVICES)"
                  class="delete"
                  @click="deleteItem"
                >
                  <i class="fal fa-trash-alt" />
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
             <ViewThirdPartyComponent 
              v-if="selectedService" 
              :serviceId="selectedService.id"
            /> 
          </div>
        </div>
      </div>

      <div v-if="isUpdateModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isUpdateModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content"
         v-resizable-modal="'IMPORT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{$t("THIRD_PARTY_SERVICE.SERVICE_PREVIEW") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openViewModal">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <EditThirdPartyComponent
                v-if="selectedService"
                :serviceId="selectedService.id"
                @onView="openEditModal"
                @onUpdateSuccess="handleServiceUpdated"
            /> 
          </div>
        </div>
      </div>

      <div
        v-if="isCreateModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isCreateModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content"
         v-resizable-modal="'THIRD_PARTY_SERVICE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{$t("THIRD_PARTY_SERVICE.ADD_SERVICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <AddThirdPartyComponent 
              @onView="openViewModal"
              @onCreateSuccess="handleServiceCreated"
            />
             
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import { cloneDeep } from "lodash";

import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";

import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ThirdPartyListTable from "@/views/Pages/AdminModule/ThirdPartyServiceModule/partials/ThirdPartyListTable.vue";
import EditThirdPartyComponent from "./components/EditThirdPartyComponent.vue";
import AddThirdPartyComponent from "./components/AddThirdPartyComponent.vue";
import ViewThirdPartyComponent from "./components/ViewThirdPartyComponent.vue";

export default {
  name : "ListThirdPartyServicesPage",

  layout: "DashboardLayout",

  components: {
    NotificationSubscription,
    [Button.name]: Button,

    ThirdPartyListTable,
    AddThirdPartyComponent ,
    EditThirdPartyComponent ,
    ViewThirdPartyComponent ,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const selectedId = this.$route.query.id;
    const action = this.$route.query.action;

  
    return {
      isViewModalOpened: (action === QUERY_ACTIONS_VIEW),
      isUpdateModalOpened: (action === QUERY_ACTIONS_EDIT),
      isCreateModalOpened: (action === QUERY_ACTIONS_ADD),
      selectedService : (selectedId) ? { id: selectedId } : null,
      renderKey: 1,
      isLoadingCreate: false,
      isLoadingUpdate: false,
      isLoadingDelete: false,
    };
  },

  methods: {

    onViewService(service){
      this.selectedService = service;
      this.openViewModal();
    },

    onEditService(service){
      this.selectedService = service;
      this.openEditModal();
    },

   async  handleServiceUpdated(service){
      this.closeModal();
  
       return await this.$refs.servicesListTable.getList();
    },

    async  handleServiceCreated(service){
      this.closeModal();
 
       return await this.$refs.servicesListTable.getList();
    },

    openCreateModal() {
      this.closeModal();
      this.isCreateModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Third Party Services Page",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openViewModal(reRender = false) {
      
      this.isViewModalOpened = true;
     
      if (reRender) {
        this.renderKey++;
      }
    
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Third Party Services Page",
          query: { id: this.selectedService.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openEditModal() {
      
      this.isUpdateModalOpened = true;
     
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Third Party Services Page",
          query: { id: this.selectedService.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeModal() {
      this.isCreateModalOpened = false;
      this.isViewModalOpened = false;
      this.isUpdateModalOpened = false;
      this.selectedService = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Third Party Services Page",
          query: {},
        }).href
      );
    },

    async deleteItem() {
      const that = this;

      await swal.fire({
        title: this.$t("IMPORTS.DELETE_THIS_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
        showLoaderOnConfirm : that.isLoadingDelete,

        preConfirm : async ()=> {
          try {
               that.isLoadingDelete = true;
                await that.$store.dispatch("thirdPartyServices/destroy", that.selectedService.id);
                
                that.renderKey++;

                that.$notify({
                  type: "success",
                  message: that.$t("IMPORTS.IMPORT_DELETED"),
                });

                that.closeModal();
                await that.$refs.servicesListTable.getList();
               
            
            } catch (error) { // Gère les erreurs
              await that.showRequestError(error);
              
              swal.showValidationMessage(`Erreur: ${error}`); 
              that.$notify({
                type: "danger",
                message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
              });
            }finally{
              that.isLoadingDelete = false;
            }
        }
      });

     
    },
  },
};
</script>
