<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ service.name }}</h3>
      <div class="col-12">
        
        <dl class="row">
          <dt class="col-sm-4">{{ $t("THIRD_PARTY_SERVICE.SERVICE_TYPE") }}</dt>
          <dd class="col-sm-8">
            <div>{{ service.slug }}</div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("THIRD_PARTY_SERVICE.SERVICE_ACTIVE") }}</dt>
          <dd class="col-sm-8">
            <div>{{service.is_active ? $t("COMMON.YES") : $t("COMMON.NO") }}</div>
          </dd>
        </dl>
        
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="service.organization" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS) && service.reseller"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="service.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="service.created_at">
            {{ $formatDate(service.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="service.updated_at">
            {{ $formatDate(service.updated_at) }}
          </dd>
        </dl>
      
      </div>
  </div>
</template>
<script>


export default {
  name: "ThirdPartyViewGlobal",

  components: { },

  props: ["service"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    
  },
};
</script>
