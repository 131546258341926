

export const STATUS_PENDING = "PENDING";
export const STATUS_SUCCESS = "SUCCESS";
export const STATUS_FAILED = "FAILED";
export const ACTION_CREATE = "CREATE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_DELETE = "DELETE";

export const SERVICE_MODE_TEST = "test";
export const SERVICE_MODE_PROD = "prod";

export const SERVICE_TYPE_QUICKBOOKS = "quickbooks";

export const SERVICES_MODES = [
  {code : SERVICE_MODE_TEST , label : "Test"},
  {code : SERVICE_MODE_PROD , label : "Prod"},
];

export const SERVICES_TYPES = [
  {
    code : SERVICE_TYPE_QUICKBOOKS ,
    label : "QuickBooks",
    
    fields : [
      {
          key : "client_id",
          label : "client_id",
          type : "text",
          descriptions : "Une petite description",
      },
      {
          key : "client_secret",
          label : "client_secret",
          type : "text"
      },
      
      {
          key : "realm_id",
          label : "Company Id",
          type : "text"
      },
    ],
  },
];


export const defaultDataModel = {
  type: "third-party-services",
  name: null,
  slug: null,
  mode : SERVICE_MODE_TEST,
  test_config: null,
  prod_config: null,
  relationshipNames: ["organization","reseller"],
  organization: {
    type: "organizations",
    id: null,
  },
  resseler: {
    type: "resellers",
    id: null,
  },
};
