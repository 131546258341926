<template>
  <div class="container-fluid">
    <ThirdPartyForm
      :loading="loading"
      :selectedService="service"
      :formErrors="formErrors"
      :action=" QUERY_ACTIONS_ADD"
      @onSubmitSuccess="handleCreateSuccess"
      @onFormUpdated ="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import ThirdPartyForm from "@/views/Pages/AdminModule/ThirdPartyServiceModule/partials/ThirdPartyForm.vue";
import {
  defaultDataModel
} from "@/constants/thirdPartyServices";



import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";

export default {

  name : "AddThirdPartyComponent",
  
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ThirdPartyForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      service : cloneDeep(defaultDataModel),
      formErrors: null,
      loading: false,
      QUERY_ACTIONS_ADD
    };
  },

  methods: {
  
    handleCreateSuccess(service){
      this.$emit("onCreateSuccess", service);
    }
  },
};
</script>
