<template>
  <div class="elite-tabs-wrapper-content">
   
     <div class="custom-header">
      <h3>{{ `${service.slug}` }} - {{ $t("COMMON.LOGS") }}</h3>
    </div>
    <log-list-table :filterSubject="service" /> 
  </div>
</template>

<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "ThirdPartyViewLogs",

  components: { LogListTable },

  props: ["service"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    
  },
};
</script>
