<template>

  <div>
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
 
  <form
    class="add-form"
    ref="createForm"
    :disabled="loading"
    @submit.prevent="() => {}"
    @keydown.enter.prevent="() => {}"
  >


  <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <base-input
        :label="$t('COMMON.RESELLER')"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :reseller="service.reseller ? service.reseller.id : null"
          :filterable="true"
          :showAll="false"
          :allowNone="true"
          @resellerChanged="
            (resellerId) => {
              service.reseller.id = resellerId;
              service.organization.id = null;
              handleFormUpdated();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div>

    <!-- Organization -->
    <div class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
     >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="service.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
             service.organization.id = organizationId;
             handleFormUpdated();
            }
          "
          :disabled="!!service.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <!-- <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="service.name"
        :disabled="loading"
        @change="handleFormUpdated"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div> -->


   
    <div class="form-wrapper mb-2"
    :class="{full : !service.slug}">

      <base-input
        :label="`${$t('THIRD_PARTY_SERVICE.SERVICE_TYPE')} (*)`"
        :placeholder="$t('THIRD_PARTY_SERVICE.SERVICE_TYPE')"
      >
      <el-select
         v-model="service.slug"
         :placeholder="$t('THIRD_PARTY_SERVICE.SERVICE_TYPE')"
         :filterable="false"
         :disabled="loading"
         :multiple="false"
       >
        
         <el-option
           v-for="type in allServicesTypes"
           :key="type.code"
           :value="type.code"
           :label="type.label"
          
         >
       
         </el-option>
       </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.slug" />
    </div>

    <div class="form-wrapper mt-3" v-if="service.slug">
        <base-input
          :label="`${$t('THIRD_PARTY_SERVICE.SERVICE_MODE')} (*)`"
          :placeholder="$t('THIRD_PARTY_SERVICE.SERVICE_MODE')"
        >
          <el-radio-group v-model="service.mode">
            <el-radio
            v-for="mode in allServicesModes"
            :key="mode.code"
            :label="mode.code" 
            >
            {{ mode.label }}
          </el-radio>
           
          </el-radio-group>
        </base-input>

      
    </div>
    
    <div class="form-wrapper full" v-if="service.slug">

      <el-tabs v-model="activeTabMode" :stretch="true">
        <el-tab-pane
          :label="$t('THIRD_PARTY_SERVICE.SERVICE_TEST_CONFIG')"
          :name="mode_test"
        >

          <ThirdPartyConfigEditor
            v-model = "service.test_config"
            :serviceType="service.slug"
            :disabled="loading"
            :title="$t('THIRD_PARTY_SERVICE.SERVICE_TEST_CONFIG')"
          />

        </el-tab-pane>

        <el-tab-pane
          :label="$t('THIRD_PARTY_SERVICE.SERVICE_PROD_CONFIG')"
          :name="mode_prod"
        >

          <ThirdPartyConfigEditor
            v-model = "service.prod_config"
            :serviceType="service.slug"
            :disabled="loading"
            :title="$t('THIRD_PARTY_SERVICE.SERVICE_PROD_CONFIG')"
          />

        </el-tab-pane>
      </el-tabs>


    </div>
    

    <div class="submit-wrapper">
      <base-button
         type="button"
         class="btn btn-sm elite-submit"
         native-type="button"
        :disabled="loading"
        :loading="loading"
        @click="handleSubmit"
      >
       
        {{ (currentAction === QUERY_ACTIONS_ADD)
          ? $t("THIRD_PARTY_SERVICE.ADD_THIS_SERVICE") 
          : $t("THIRD_PARTY_SERVICE.EDIT_THIS_SERVICE")
        }}
      </base-button>
    </div>
  </form>
</div>
</template>

<script>
import { 
  Select,
     Option,
     Row,
     Col ,
     Card,
     Button,
     Tabs, 
     TabPane,
     Form,
     Radio,
     RadioGroup,
} from "element-ui";
import swal from "sweetalert2";
import formMixin from "@/mixins/form-mixin";
import { cloneDeep } from "lodash";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import BaseSwitch from "@/components/Inputs/BaseSwitch.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ThirdPartyConfigEditor from "@/views/Pages/AdminModule/ThirdPartyServiceModule/components/ThirdPartyConfigEditor.vue";


import {
  SERVICES_TYPES,
  SERVICES_MODES,
  SERVICE_MODE_TEST,
  SERVICE_MODE_PROD,
  SERVICE_TYPE_QUICKBOOKS,
  defaultDataModel
} from "@/constants/thirdPartyServices";

import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";

export default {
  name: "ThirdPartyForm",
  
  layout: "DashboardLayout",

  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    BaseInput,
    BaseCheckbox,
    BaseSwitch,
    ValidationError,
    OrganizationSelector,
    ResellerSelector,
    ThirdPartyConfigEditor
  },

  mixins: [formMixin],

  props: ["selectedService", "formErrors", "perPage","action"],

  data() {
         
    return {
      service : this.selectedService || defaultDataModel,
      loading: false,
      activeTabMode : SERVICE_MODE_TEST,
      mode_test : SERVICE_MODE_TEST,
      mode_prod : SERVICE_MODE_PROD,
      QUERY_ACTIONS_ADD,
      
    };
  },

  mounted() {
  },

  computed : {

   currentAction(){
      return this.action || QUERY_ACTIONS_ADD;
   },

   allServicesTypes(){
      return SERVICES_TYPES;
   },

   allServicesModes(){
      return SERVICES_MODES;
   },

   selectedMode(){
      return this.allServicesModes.find(({code})=> code === this.service?.mode);
   },

   selectedType(){
      return this.allServicesTypes.find(({code})=> code === this.service?.slug);
   },
   serviceData(){
    const service = cloneDeep(this.service);
    console.log("selectedType => " , this.selectedType)
   service.name = this.selectedType.label;
    return service
   },
  },

  methods: {

    async handleSubmit() {
     

      if(this.currentAction === QUERY_ACTIONS_ADD){
        return await this.create();
      }

      if(this.currentAction === QUERY_ACTIONS_EDIT){
        return await this.confirmUpdate();
      }

      return null;

      // this.$emit("onSubmited", this.service);
    },


    async create() {
      this.loading = true;

      const data = cloneDeep(this.serviceData);
      delete data.id;

      console.log("service-data => ", data)

      try {
        await this.$store.dispatch("thirdPartyServices/add", data);
      
          this.$notify({
            type: "success",
            message: this.$t("THIRD_PARTY_SERVICE.SERVICE_ADDED"),
          });
          this.service = await this.$store.getters["thirdPartyServices/item"];

          this.$emit("onSubmitSuccess", this.service);

        this.loading = false;
      } catch (error) {
        console.error("TPS-create => ", error)

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
       
      }finally{
        this.loading = false;
      }
    },

    async confirmUpdate() {
          await swal.fire({
    
            title: this.$t("COMMON.CONFIRM_THIS_ACTION"),
            type: "question",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: this.$t("COMMON.YES"),
            cancelButtonText: this.$t("COMMON.NO"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
            showLoaderOnConfirm : this.loading,
            allowOutsideClick: () => !swal.isLoading(), // Empêche la fermeture accidentelle pendant le chargement
    
            preConfirm : async ()=> {
             await this.update();
            }
          });
    },

    async update() {
      this.loading = true;

      const data = cloneDeep(this.serviceData);

      try {
        await this.$store.dispatch("thirdPartyServices/update", data);
      
          this.$notify({
            type: "success",
            message: this.$t("THIRD_PARTY_SERVICE.SERVICE_UPDATED"),
          });
          this.service = await this.$store.getters["thirdPartyServices/item"];

          this.$emit("onSubmitSuccess", this.service);

        this.loading = false;
      } catch (error) {
        console.error("TPS-Update => ", error);
        swal.showValidationMessage(`Erreur: ${error}`); // Gère les erreurs
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
       // this.formErrors = error.response.data.errors;
        
      }finally{
        this.loading = false;
      }
    },

   
    cancel() {
      this.$refs.createForm.reset();
    },

    handleFormUpdated() {
      this.$emit("onFormUpdated");
    },
  },
};
</script>
