<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="isLoading">
      <span class="loader"></span>
    </span>

    <div class="elite-tabs-wrapper" v-if="service">

      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      
   
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <third-party-view-global :service="service" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
        
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            service.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="service" />
          </div>
        </tab-pane>

        <tab-pane
            title="logs"
            id="999"
          
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <third-party-view-logs :service="service" />
        </tab-pane>
      </tabs>

    </div>
    
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Link,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import ThirdPartyViewLogs from "../partials/ThirdPartyViewLogs.vue";
import ThirdPartyViewGlobal from "../partials/ThirdPartyViewGlobal.vue";

export default {
  name : "ViewThirdPartyComponent",

  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Link.name]: Link,
    Tabs,
    TabPane,
    ListFileComponent,
    ThirdPartyViewLogs,
    ThirdPartyViewGlobal,
  },

  mixins: [requestErrorMixin],

  props: {
    serviceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      service: null,
      id :  this.serviceId || this.$route.query.id,
      isLoading : false,
      tabView : "global",
    };
  },

  async created() {
     this.get();
  },

  methods: {
    async get() {
      try {
        this.isLoading = true;
        const id = this.serviceId || this.service?.id || this.$route.query.id;
      //  console.log("get-id => ", id)
        await this.$store.dispatch("thirdPartyServices/get", id);
        this.service = this.$store.getters["thirdPartyServices/item"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }finally{
        this.isLoading = false;
      }
    },

    async delete() {
      const that = this;
      await swal.fire({

        title: that.$t("THIRD_PARTY_SERVICE.DELETE_THIS_SERVICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: that.$t("COMMON.YES"),
        cancelButtonText: that.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
        showLoaderOnConfirm : that.loading,
        allowOutsideClick: () => !swal.isLoading(),

        preConfirm : async function(){
          try {
              that.loading = true;

              await that.$store.dispatch("thirdPartyServices/destroy", that.id);
             
              that.goBack();
             
              that.$notify({
                type: "success",
                message: that.$t("THIRD_PARTY_SERVICE.SERVICE_DELETED"),
              });
        
          } catch (error) {
            that.loading = false;
            that.$notify({
              type: "danger",
              message: that.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });
          }
        }
      });

     
    },

    goBack() {
      this.$router.push({ name: "Third Party Services Page" });
    },
  },

  computed: {},
};
</script>
